import { useState } from 'react';
import cl from './confirmForm.module.scss';
import MyInput from '@uiComponents/MyInput/MyInput';
import MyButton from '@uiComponents/MyButton/MyButton';
import {passwordSchema} from "@components/Schemas/Schemas.ts";
import {useAuthService} from "@services/useAuthService.ts";

const ConfirmForm = ({
    clientId,
    realm,
    username,
    otp,
    redirectUrl
}: {
    clientId: string;
    realm: 'email' | 'sms';
    username: string;
    otp: string;
    redirectUrl: string;
}) => {
    const [password, setPassword] = useState<string>('');
    const [isWrongPassword, setIsWrongPassword] = useState<boolean>(false);

    const {getRedirectLink, passwordlessConfirm, loadingStatus} = useAuthService();

    const confirm = async () => {
        try {
            const response = await passwordlessConfirm(clientId, {
                realm,
                username,
                otp,
                password
            });

            window.location.replace(
                getRedirectLink(redirectUrl, {
                    ...response
                })
            );
        } catch (e) {
            //
        }
    };
    if (loadingStatus === 'loading') {return <></>}

    return (
        <form className={cl.confirmForm} onSubmit={(e) => e.preventDefault()}>
            <div className={cl.inputsWrapper}>
                <div className={cl.formTitle}>Придумайте пароль</div>
                {isWrongPassword && <div className={cl.errorMessage}>Введите пароль. Минимум 5 символов</div>}
                <MyInput
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    type="password"
                    placeholder="Пароль"
                    addClasses={cl.loginFormInput}
                />
            </div>

            <div className={cl.buttonsWrapper}>
                <MyButton active={true} addClasses={cl.loginFormButton}
                          onClick={() => {
                              passwordSchema.validate(password)
                                  .then(() => {
                                      setIsWrongPassword(false)
                                      confirm()
                                  })
                                  .catch(() => setIsWrongPassword(true))

                          }}
                >Войти
                </MyButton>
            </div>
        </form>
    );
};

export default ConfirmForm;
