import cl from './authPage.module.scss';
import Logo from '@uiComponents/Logo/Logo.tsx';
import { useQuery } from '@hooks/query.hook.ts';
import { useEffect } from 'react';
import ConfirmForm from './components/ConfirmForm/ConfirmForm.tsx';
import LoginForm from './components/LoginForm/LoginForm.tsx';
import IndexDescription from "@uiComponents/IndexDescription/IndexDescription.tsx";

const AuthPage = () => {
    const clientId = useQuery('clientId');
    const redirectUrl = useQuery('redirectUrl');
    const backRedirectUrl = useQuery('backRedirectUrl');
    const username = useQuery('username');
    const realm = useQuery<'email' | 'sms'>('realm');
    const otp = useQuery('otp');

    useEffect(() => {if (clientId) {localStorage.setItem('clientId', clientId);}}, [clientId]);

    if (!clientId || !redirectUrl) {return <></>;}

    return (
        <div className={cl.authPage}>
            <Logo />
            <div className={cl.wrapper}>
                {realm && otp && username ? (
                    <div className={cl.container}>
                        <ConfirmForm
                            clientId={clientId}
                            realm={realm}
                            username={username}
                            otp={otp}
                            redirectUrl={redirectUrl}
                        />
                    </div>
                ) : (
                    <>
                        {clientId && redirectUrl && (
                            <div className={cl.container}>
                                <LoginForm
                                    clientId={clientId}
                                    redirectUrl={redirectUrl}
                                    backRedirectUrl={backRedirectUrl}
                                />
                            </div>
                        )}
                    </>
                )}

                <IndexDescription />
            </div>
        </div>
    );
};

export default AuthPage;
