import cl from './indexDescrItem.module.scss';

type Content = {
    type: 'ul' | 'text',
    text: string[]
}
export interface IAuthDescrItemProps {
    title: string;
    content: Content
}
const IndexDescrItem = ({title, content}: IAuthDescrItemProps) => {
    return (
        <div className={cl.container}>
            <div className={cl.title}>{title}</div>

            {content.type === 'text' && (
                <div className={cl.content}>
                    {content.text.map((t, index) =>
                        <div key={String(Math.random() + index)}
                             dangerouslySetInnerHTML={{__html: t}}
                        ></div>)}
                </div>
            )}

            {content.type === 'ul' && (
                <ul className={cl.content}>
                        {content.text.map((t, index) =>
                            <li key={String(Math.random() + index)}
                                dangerouslySetInnerHTML={{__html: t}}>
                            </li>)}
                </ul>
            )}
        </div>
    );
};

export default IndexDescrItem;