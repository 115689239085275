import cl from './myInput.module.scss';
import {ChangeEvent, useEffect, useRef, useState} from "react";

interface MyInputProps {
    placeholder: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    addClasses?: string;
    value: string;
    type: string
}

const MyInput = ({placeholder, addClasses, onChange, type} : MyInputProps) => {
    const [isCapslockPressed, setIsCapsLockPressed] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const checkOnCapslock = (e: KeyboardEvent) => {
        const caps = e.getModifierState && e.getModifierState( 'CapsLock' );
        setIsCapsLockPressed(caps)
    }

    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.addEventListener('keydown', checkOnCapslock)
        }
        return () => {
            if (inputRef?.current) {
                inputRef.current.removeEventListener('keydown', checkOnCapslock)
            }
        }
    }, []);

    return (
        <>
            {type === 'password' && isCapslockPressed && (
                <div className={cl.capslock}>Внимание: включён Caps-Lock</div>
            )}

            <input
                className={`${cl.myInput} ${addClasses ? addClasses : ''}`}
                placeholder={placeholder}
                ref={inputRef}
                onChange={(e) => {

                    onChange(e)
                }}
                type={type}
            />
        </>
    );
};

export default MyInput;