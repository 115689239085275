import cl from './myButton.module.scss';
import { ReactNode } from 'react';

interface MyButtonProps {
    onClick?: () => void;
    children: ReactNode;
    active?: boolean;
    addClasses?: string;
}

const MyButton = ({ children, active, onClick, addClasses }: MyButtonProps) => {
    return (
        <button
            className={`${cl.myButton} ${active ? cl.active : ''} ${addClasses ? addClasses : ''}`}
            onClick={() => (onClick ? onClick() : null)}
        >
            {children}
        </button>
    );
};

export default MyButton;
