import '@styles/styles.scss';
import cl from './app.module.scss';
import pageModuleCSS from '@styles/page.module.scss';


import AppRouter from "../AppRouter/AppRouter.tsx";
import {ToastContainer} from "react-toastify";

const customToastStyle = {
    toastStyle: {
        borderRadius: '20px',
        padding: '20px 25px',
        fontSize: '15px',
        boxShadow: '0px 4px 44px 0px rgba(0, 0, 0, 0.25)'
    },
    bodyStyle: {
        padding: '0'
    },
    progressStyle: {
        backgroundColor: 'var(--main-black)',
        height: '3px'
    }
};
function App() {
    return (
        <div className={cl.app}>
            <main className={pageModuleCSS.page}>
                <AppRouter/>
                <ToastContainer {...customToastStyle}/>
            </main>
        </div>
    );
}

export default App;