import AuthPage from '../pages/AuthPage/AuthPage.tsx';
import ErrorPage from '../pages/ErrorPage/ErrorPage.tsx';
import { Navigate, RouteObject } from 'react-router';
import RestorePage from '../pages/RestorePage/RestorePage.tsx';

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <Navigate to="/auth" />,
        errorElement: <ErrorPage />
    },
    {
        path: '/auth',
        element: <AuthPage />
    },
    {
        path: '/restore',
        element: <RestorePage />
    }
];
